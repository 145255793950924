body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  transform-style: preserve-3d;
  color: #000;
  .alignleft {
    float: left;
    margin: 8px;
  }
  .alignright {
    float: right;
    margin: 8px;
  }
  .aligncenter {
    float: center;
    margin: 8px;
  }
  .article-container #article {
    animation-name: fade-in;
    animation-fill-mode: both;
    animation-duration: 0.1s;
    transition: ease-in;
  }
  $animationDelay: 1;
  @for $i from 1 through 100 {
    .article-container #article:nth-of-type(#{$i}) {
      animation-delay: #{0.1+ ($i)/100}s;
    }
  }

  .nav-link {
    a { color: #fff !important }
  }

  .custom-a {
    a { color: $green }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  #paginate {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 2rem;
    width: 400px;
    .back {
      grid-column: 1 / 2;
    }
    .page-indicator {
      display: flex;
      justify-content: center;
      flex-direction: column;
      grid-column: 2 / 3;
      p {
        margin: 0 6px;
        width: 90px;
      }
    }
    .next {
      grid-column: 3 / 4;
    }
  }
  .paginate {
    padding: 8px 24px;
    font-size: 18px;
    color: white;
    background-color: rgb(46, 163, 153);
    border: none;
    border-radius: 4px;
    &:hover {
      color: whitesmoke;
    }
  }
  .hide {
    display: none;
  }
  .display {
    animation: loaded 0.7s;
  }
  .custom-a {
    .clickEl {
      display: flex;
    }
  }
  .content-container {
    max-width: 1280px;
    width: 100%;
    margin: auto;
    padding: 0 180px;
  }
  .App {
    transform-style: preserve-3d;
  }
  h1,
  h3 {
    padding: 0;
    margin: 0;
  }
  h1 {
    font-size: 100px;
  }
  h2 {
    font-family: $heading-font;
    font-size: 48px;
    font-weight: 900;
  }
  h3 {
    font-size: 32px;
    font-weight: 900;
  }
  .c-row {
    display: flex;
    flex-direction: row;
  }
  .c-col {
    display: flex;
    flex-direction: column;
  }
  .p-40 {
    padding: 0 40px;
  }
  .p-80 {
    padding: 0 80px;
  }
  .fill-g {
    background-color: $green;
  }
  /* slide up on load */
  .animate {
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    transition-delay: 0.2s;
    transition-duration: 1.95s;
    opacity: 0;
    transform: translateY(40px);
    &.anim_active {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .header-container {
    height: 100vh;
  }

  .custom-a {
    a {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .transition-elem {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    z-index: 100;
    &.anim-transition {
      width: 100vw;
      &#transition-main {
        transition-duration: 0.85s;
        transition-delay: 0.13s;
      }

      &#transition-secondary {
        transition-duration: 0.72s;
      }
    }
    &.anim-done {
      &#transition-secondary {
        transition-duration: 0;
        width: 0;
      }
      &#transition-main {
        transition-duration: 0;
        width: 0;
      }
    }
    &.transition-load {
      transition: width 1000ms cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
    }
  }

  .loading-title {
    text-align: center;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    mix-blend-mode: screen;
    position: relative;
    z-index: 101;

    &.green {
      .loading-logo {
        &.white {
          display: none;
        }
      }
      .title {
        color: $green;
      }
      .loading-content {
        color: $green;
      }
    }
    &.white {
      .loading-logo {
        &.green {
          display: none;
        }
      }
      .title {
        color: #fff;
      }
      .loading-content {
        color: #fff;
      }
    }
    .loading-logo {
      max-width: 160px;
      margin-bottom: 32px;
    }
    .loading-content {
      color: #fff;
      font-weight: 700;
      font-family: $heading-font;
      font-size: 36px;
      @include media-breakpoint-down(sm) {
        font-size: 5vw;
      }
    }
    .title {
      font-family: $heading-font;
      color: $green;
      font-weight: 700;
      font-size: 100px;
      @include media-breakpoint-down(sm) {
        font-size: 8vw;
      }
    }
  }

  .custom-a {
    a {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
html {
  -webkit-font-smoothing: antialiased;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  body {
    font-family: $heading-font;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }
    audio,
    canvas,
    progress,
    video {
      display: inline-block;
    }
    audio:not([controls]) {
      display: none;
      height: 0;
    }
    progress {
      vertical-align: baseline;
    }
    [hidden],
    template {
      display: none;
    }
    a {
      background-color: transparent;
      -webkit-text-decoration-skip: objects;
      text-decoration: none;
    }
    a:active,
    a:hover {
      outline-width: 0;
      text-decoration: none;
    }
    abbr[title] {
      border-bottom: none;
      text-decoration: underline;
      text-decoration: underline dotted;
    }
    b,
    strong {
      font-weight: inherit;
      font-weight: bolder;
    }
    dfn {
      font-style: italic;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 0;
      margin-bottom: 16px;
    }
    mark {
      background-color: #ff0;
      color: #000;
    }
    small {
      font-size: 80%;
    }
    sub,
    sup {
      font-size: 75%;
      line-height: 0;
      position: relative;
      vertical-align: baseline;
    }
    sub {
      bottom: -0.25em;
    }
    sup {
      top: -0.5em;
    }
    img {
      border-style: none;
    }
    svg:not(:root) {
      overflow: hidden;
    }
    code,
    kbd,
    pre,
    samp {
      font-family: monospace, monospace;
      font-size: 1em;
    }
    figure {
      margin: 1em 40px;
    }
    hr {
      box-sizing: content-box;
      height: 0;
      overflow: visible;
    }
    button,
    input,
    optgroup,
    select,
    textarea {
      font: inherit;
      margin: 0;
    }
    optgroup {
      font-weight: 700;
    }
    button,
    input {
      overflow: visible;
    }
    button,
    select {
      text-transform: none;
    }
    [type="reset"],
    [type="submit"],
    button,
    html [type="button"] {
      -webkit-appearance: button;
    }
    [type="button"]::-moz-focus-inner,
    [type="reset"]::-moz-focus-inner,
    [type="submit"]::-moz-focus-inner,
    button::-moz-focus-inner {
      border-style: none;
      padding: 0;
    }
    [type="button"]:-moz-focusring,
    [type="reset"]:-moz-focusring,
    [type="submit"]:-moz-focusring,
    button:-moz-focusring {
      outline: 1px dotted ButtonText;
    }
    fieldset {
      border: 1px solid silver;
      margin: 0 2px;
      padding: 0.35em 0.625em 0.75em;
    }
    legend {
      box-sizing: border-box;
      color: inherit;
      display: table;
      max-width: 100%;
      padding: 0;
      white-space: normal;
    }
    textarea {
      overflow: auto;
    }
    [type="checkbox"],
    [type="radio"] {
      box-sizing: border-box;
      padding: 0;
    }
    [type="number"]::-webkit-inner-spin-button,
    [type="number"]::-webkit-outer-spin-button {
      height: auto;
    }
    [type="search"] {
      -webkit-appearance: textfield;
      outline-offset: -2px;
    }
    [type="search"]::-webkit-search-cancel-button,
    [type="search"]::-webkit-search-decoration {
      -webkit-appearance: none;
    }
    ::-webkit-input-placeholder {
      color: inherit;
      opacity: 0.54;
    }
    ::-webkit-file-upload-button {
      -webkit-appearance: button;
      font: inherit;
    }
    * {
      box-sizing: inherit;
    }
    *:before {
      box-sizing: inherit;
    }
    *:after {
      box-sizing: inherit;
    }
  }
}

@media only screen and (max-width: 600px) {
  #blog-container,
  .paginate-container {
    display: flex;
    justify-content: center;
  }
}
