section.team-member {
  padding: 40px 0;
  .team-member-content {
    .title {
      font-size: 32px;
      font-weight: 900;
      letter-spacing: -1.4px;

    }
    .subtitle {
      font-size: 24px;
      font-weight: 400;
      letter-spacing: -1px;
      @include media-breakpoint-down(sm) {
        font-size: 18px;
      }
    }
    hr {
      background-color: $green;
      border-width: 5px;
      width: 80px;
      margin: 24px 0;
    }
    .summary {
      font-size: 48px;
      font-weight: 700;
      letter-spacing: -3px;
    }
    .content {
      font-size: 18px;
    }
  }
  .team-member-photo {
  }
}
