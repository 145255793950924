.more-info {
    display: flex;
    justify-content: center;

    .text-box {
        margin: 2rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        a {
            color: $green;
        }
    }
}

@media only screen and (max-width: 480px) {
    .more-info {
        display: flex;
        justify-content: center;
        flex-direction: column;
    
        .text-box {
            margin: 1rem;
        }
    }
  }