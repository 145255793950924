.header-wrapper {
  font-family: $heading-font;
  position: fixed;
  z-index: 10;
  background-color: #2ea399;
  width: 100%;
  height: auto;
  display: flex;
  .expanded-content {
    display: none;
    &.active {
      display: block;
      @media (min-width: 1001px) {
        display: none;
      }
    }
    width: 100%;
    text-align: center;
    position: absolute;
    top: calc(50vh - 55px);
    left: 0;
    transform: translateY(-50%);
    .exanded-link-container {
      overflow: hidden;
      margin-bottom: 24px;
      .custom-a {
        animation-name: animExpandedNavLinks;
        animation-duration: 1.3s;
        animation-delay: 0.1s;
        animation-timing-function: cubic-bezier(0.2, 1, 0.2, 1);
        transform-origin: 0px center;
        animation-fill-mode: both;
        backface-visibility: hidden;
        color: $green;
        font-size: 24px;

        .clickEl {
          justify-content: center;
          
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .header-navbar {
    transition-duration: 0.5s;
    transform: translateY(-40px);
    color: white;
    &.active {
      transform: translateY(0);
    }
    display: flex;
    padding: 24px 40px;
    max-width: 1280px;
    width: 100%;
    margin: auto;
    .header-nav {
      margin-left: auto;
      position: relative;
      display: flex;
      align-items: center;
      .nav-list {
        list-style: none;
        display: flex;
        margin: 0;
        @media (max-width: 1100px) {
          display: none;
        }
        .nav-item {
          padding: 0;
          margin-left: 16px;
          .nav-link {
            padding: 0;
            margin: 8px 16px;
            color: #fff;
            &.nav-active-link {
              font-weight: bold;
            }
            &::after {
              content: "";
              display: block;
              background-color: white;
              height: 1px;
              width: 0;
              transition: 0.5s cubic-bezier(0.4, 0.6, 0.6, 1);
            }
            &:hover {
              &::after {
                width: 100%;
              }
            }
          }
        }
      }
    }
    .nav-hamburger {
      @media (min-width: 1101px) {
        display: none;
      }
      width: 20px;
      height: 15px;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      outline: 1px solid transparent;
      will-change: contents;
      &:hover {
        cursor: pointer;
      }
      &:before {
        content: "";
        width: 46px;
        height: 46px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -23px;
        margin-top: -23px;
        border-radius: 50%;
        background-color: #fff;
        display: inline-block;
        pointer-events: none;
        -webkit-transform-style: preserve-3d;
        transition: transform 0.9s cubic-bezier(0.2, 1, 0.2, 1) 0s;
        transform: scale(1);
      }
      &.expanded {
        &:before {
          transform: scale(45);
        }
        .nav-hamburger-dash {
          &:first-child {
            transition-duration: 0.2s;

            transform: rotate(45deg);
          }
          &:nth-child(2) {
            transform: rotate(-45deg);
            transition-duration: 0.2s;
            top: 0;
          }
          &:nth-child(3) {
            display: none;
          }
        }
      }
      .nav-hamburger-dash {
        &:first-child {
          position: absolute;
          top: 0;
          left: 0;
        }
        &:nth-child(2) {
          position: absolute;
          left: 0;
          top: 50%;
        }
        &:nth-child(3) {
          position: absolute;
          top: 100%;
          left: 0;
        }
        width: 100%;
        height: 2px;
        background: $green;
      }
    }
    .header-logo {
      display: flex;
      flex-direction: row;
      justify-content: center;

      a {
        display: block;
        .logo-text {
          display: inline-block;
        }
      }
      img {
        height: 72px;
        width: auto;
        margin-right: 16px;
        vertical-align: inherit;

        @include media-breakpoint-down(sm) {
          height: 64px;
        }
        @include media-breakpoint-down(xs) {
          height: 48px;
        }
      }
      h3 {

        @include media-breakpoint-down(sm) {
          font-size: 32px;
          line-height: normal;
        }
        @include media-breakpoint-down(xs) {
          font-size: 26px;
          line-height: normal;
        }

        @media only screen and (max-width: 400px) {
          display: none;
        }
        margin: 0;
        font-weight: 400;
        font-size: 36px;
        font-family: $heading-font;
        text-transform: uppercase;
        letter-spacing: -1px;
        line-height: 40px;
        color: #fff;
      }
    }
  }
}

@keyframes animExpandedNavLinks {
  0% {
    transform: skewY(40deg) translateY(50px);
  }
  100% {
    transform: skewY(0deg) translate(0px);
  }
}

@keyframes animUnderline {
  0% {
    border: 1px solid white;
  }
}
