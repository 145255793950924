section.about-intro {
  padding: 40px 0;
  h2 {
    font-size: 56px;
    font-weight: 900;
    letter-spacing: -1.4px;
  }
  p {

  }
  ul {
    li {

    }
  }
}
