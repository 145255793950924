section.flip-cards {
  @include media-breakpoint-down(md) {
    display: none;
  }
  .flip-card {
    margin:40px auto;
    background-color: $green;
    transition: background-color .3s;

    box-shadow: 0 4px 34px rgba(0,0,0,0.25);
    border-radius: 4px;
    height: 240px;
    width: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    &:hover {
      background-color: #fff;
      transition: background-color .3s;
      .top {
        display: none;
      }
      .bottom {
        display: block;
      }
    }
    .top {
      h3 {
        text-align: center;
        color: #fff;
        font-size: 30px;
        font-weight: 900;
        margin: 0;

      }
    }
    .bottom {
      display: none;
      ul, .flip-card-list {
        padding: 8px;
        margin:0;
        li, .card-item {
          color: $green;
          font-size: 13px;
          margin-bottom: 8px;
          font-weight: 700;
        }
      }
    }
  }
}
