@font-face {
  font-family: 'NeueEinstellung';
  src: url('./fonts/NeueEinstellung-Black.eot?#iefix') format('embedded-opentype'),  url('./fonts/NeueEinstellung-Black.woff') format('woff'), url('./fonts/NeueEinstellung-Black.ttf')  format('truetype'), url('./fonts/NeueEinstellung-Black.svg#NeueEinstellung-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}


@font-face {
  font-family: 'NeueEinstellung';
  src: url('./fonts/NeueEinstellung-Bold.eot?#iefix') format('embedded-opentype'),  url('./fonts/NeueEinstellung-Bold.woff') format('woff'), url('./fonts/NeueEinstellung-Bold.ttf')  format('truetype'), url('./fonts/NeueEinstellung-Bold.svg#NeueEinstellung-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}


@font-face {
  font-family: 'NeueEinstellung';
  src: url('./fonts/NeueEinstellung-Regular.eot?#iefix') format('embedded-opentype'),  url('./fonts/NeueEinstellung-Regular.woff') format('woff'), url('./fonts/NeueEinstellung-Regular.ttf')  format('truetype'), url('./fonts/NeueEinstellung-Regular.svg#NeueEinstellung-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NeueEinstellung';
  src: url('./fonts/NeueEinstellung-Medium.eot?#iefix') format('embedded-opentype'),  url('./fonts/NeueEinstellung-Medium.woff') format('woff'), url('./fonts/NeueEinstellung-Medium.ttf')  format('truetype'), url('./fonts/NeueEinstellung-Medium.svg#NeueEinstellung-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}
