.contact-content {
  padding: 40px 0;
  .heading {
    font-size: 56px;
    font-weight: 900;
  }
  hr {
    background-color: $green;
    width: 80px;
    height: 4px;
    align: left;
    margin-bottom: 24px;
  }
  .contact-group {
    display: flex;
    align-items: center;
    margin: 24px 0;
    .contact-icon {
      transform: scale(.7);
    }
    .contact-item {
      margin-left: 32px;
      color: #000;
      font-size: 24px;
    }
  }
}
