section.typewriter {
  background-color: $green;
  overflow: hidden;
  .typewriter-wrapper {
    .typewriter-grid {
      display: -ms-grid;
      display: grid;
      -ms-grid-rows: 30vh 40vh 30vh;
      grid-template-rows: 30vh 40vh 30vh;
      -ms-grid-columns: 65% 5% 30%;
      grid-template-columns: 65% 5% 30%;
      padding: 0 40px;
      max-width: 1280px;
      margin: 0 auto;
      @include media-breakpoint-down(md) {
        grid-template-columns: 100%;
        grid-template-rows: 40vh 40vh 20vh;

      }
      @include media-breakpoint-down(sm) {
        grid-template-rows: 40vh 30vh 30vh;

      }

      .typewriter-heading {
        -ms-grid-row: 2;
        grid-row: 2;
        @include media-breakpoint-down(md) {
          grid-row: 2;
          -ms-grid-row: 2;

        }
        animation: animHeader;
        animation-duration: 1.2s;
        animation-fill-mode: backwards;
        animation-timing-function: cubic-bezier(0.445, 0.74, 0.095, 1.185);
        display: flex;
        align-items: flex-start;
        z-index: 2;
        .typewriter-heading-text {
          @include media-breakpoint-down(sm) {
            font-size: 12vw;
          }
          font-size: 100px;
          color: #fff;
          font-family: $heading-font;
          font-weight: 700;
          display: inline-block;
          position: absolute;
          br {
            height: 0;
          }
          //display: none;
          &.active {
            //display: block;
          }
        }
      }
      .typewriter-bar {
        @include media-breakpoint-down(md) {
          display: none;
        }
        -ms-grid-column: 2;
        grid-column: 2;
        -ms-grid-row: 1;
        -ms-grid-row-span: 3;
        grid-row: 1/4;
        position: relative;
        .white-bar {
          width: 2px;
          height: 30vh;
          //min-height: 220px;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          background-color: #fff;
          .progress-bar {
            width: 100%;
            height: 100%;
            background: $green;
            display: inline-block;
            position: absolute;
            top: -30vh;
            transition: top 0.73s cubic-bezier(0.2, 0.6, 0.4, 1);
            &.animateBar {
              animation: ProgressBar 5s;
              top: 0;
            }
            &.animateBar-up {
              animation: ProgressBar-up 0.8s;
            }
          }
        }
      }
      .typewriter-content {
        @include media-breakpoint-down(md) {
          grid-column: 1;

        }

        -ms-grid-column: 3;
        grid-column: 3;
        -ms-grid-row: 3;
        grid-row: 3;
        -ms-grid-row-align: end;
        align-self: start;
        .typewriter-content-text {
          @include media-breakpoint-down(sm) {
            font-size: 22px;
          }
          font-family: $heading-font;
          font-weight: 700;
          font-size: 26px;
          color: #fff;
          opacity: 0;
          margin: 0;
          line-height: 1.1;
          &.active {
            animation: fadeInOut 5s;
            animation-fill-mode: both;
            animation-timing-function: cubic-bezier(0.2, 0.6, 0.4, 1);
          }
          &.done {
            animation: fadeIn 1.6s;
            animation-fill-mode: both;
            animation-timing-function: cubic-bezier(0.2, 0.6, 0.4, 1);
          }
        }
      }
    }
  }
}
@keyframes ProgressBar {
  0% {
    top: -30vh;
  }
  100% {
    top: 0;
  }
}
@keyframes ProgressBar-up {
  0% {
    top: 0px;
  }
  100% {
    top: 30vh;
  }
}
@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeIn {
  100% {
    opacity: 1;
  }
}
@keyframes animHeader {
  0% {
    transform: scaleY(0.01) translate3d(0, 550px, 0) skew(-10deg, -30deg);
    opacity: 0;
    transform-origin: bottom left;
  }
  to {
    transform: scaleX(1) translateZ(0) skew(0deg, 0deg);
    opacity: 1;
    transform-origin: bottom left;
  }
}
