section.footer-links {
  padding: 40px 0;
  .link-boxes {
    .link-box {
      border-right: 1px solid rgba(0,0,0,.1);
      float: left;
      width: calc(25% - 1px);
      padding: 20px 24px;
      .custom-a {
        display: flex;
        color: $green;
        justify-content: center;

        .cta-arrow {
          max-width: 12px;
          display: inline-flex;
          @media only screen and (max-width: 768px) {
            display: none;

          }
          margin-left: 10px;
          transition: margin-left .3s;

        }
        &:hover {
          .cta-arrow {
            margin-left: 16px;
            transition: margin-left .3s;

          }
        }
      }
      @media only screen and (max-width: 992px) {
        width: 100%;
        border-right: none;
      }
      .para {
        margin-top: 16px;
        font-family: $heading-font;
        font-weight: 500;
      }
    }
  }


}
