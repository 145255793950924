section.copyright {
  .copyright-container {
    display: flex;
    padding: 16px 0;
    align-items: center;
    justify-content: center;
    img {
      max-width: 48px;
      margin-right: 16px;
    }
    p.copyright-text {
      font-family: $heading-font;
      font-weight: 500;
      margin: 0;
      .custom-a {
        display: inline;
        .clickEl {
          display: inline;
        }
      }
      a {
        color: $green !important;
      }
    }
  }
}
