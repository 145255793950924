section.media {
  padding: 24px 0;
  h2 {
    color: $green;
    font-weight: 900;
    font-size: 24px;
    letter-spacing: -1.4px;
    font-family: $heading-font;
    .custom-a {
      .clickEl {
        justify-content: flex-start;
      }
    }
  }
  .custom-a {
    .clickEl {
      justify-content: flex-end;
      @include media-breakpoint-down(md) {
        justify-content: center;

      }
    }
  }
  .featured-image {
    text-align: center;
    img {
      width: 75%;
    }
  }
  .media-card-link {
    display: inline-block;
    .media-card {
      width: 300px;
      height: 300px;
      background-repeat: no-repeat;
      background-size: cover;
      margin: 16px 0;
      position: relative;
      .media-card-overlay {
        background-color: $green;
        opacity: .7;
        width: 100%;
        height: 100%;
        
      }
      .media-card-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 16px 8px;
        text-align: right;
        background-color: transparent;
        transition: background-color .7s;
        .top-half {
          text-align: start;
          color: white;
          font-weight: bold;
          margin-left: 1rem;
        }
        p {
          color: #fff;
          font-family: $heading-font;
          font-weight: 900;
          font-size: 20px;
          margin: 0;
        }
      }
    }
    &:hover {
      .media-card-content {
        background-color: $green;
        transition: background-color .7s;


      }
    }
  }

}
