body section.secondary-content {
  padding: 40px 0;
  .content {
    text-align: center;
    margin: 0 auto;
    h2 {
      font-weight: 600;
    }
  }
}
