section.partners {
  padding: 24px 0;
  h2 {
    text-align: center;
    color: $green;
    font-weight: 900;
    font-size: 24px;
    letter-spacing: -1.4px;
    font-family: $heading-font;
  }
  .partner-logo {
    max-width: 160px;
    margin: 16px;
  }
}
