section.events-section {
  .events-container {
    padding: 40px 0;
    h2 {
      color: $green;
    }
    .event {
      padding: 16px 0;
    }
  }
}
