body header.custom-header {
  background-color: $green;
  display: flex;
  align-items: center;
  padding-top: 92px;

  .header-heading {
    color: #fff;
    letter-spacing: -2px;
    font-weight: 700;
    font-size: 120px;
    font-family: $heading-font;
    margin: 0;
    &.small-font {
      font-size: 64px;
      @include media-breakpoint-down(lg) {
        font-size: 48px;
      }
      @include media-breakpoint-down(md) {
        font-size: 32px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 24px;
      }
    }
    @include media-breakpoint-down(md) {
      font-size: 80px;
      letter-spacing: normal;
    }
    @include media-breakpoint-down(sm) {
      font-size: 48px;
      letter-spacing: normal;

    }
  }
}
