section.main-content {
  padding: 40px 0;
  .main-content-l {
    padding-right: 32px;
    h2 {
      color: $green;
      font-weight: 900;
    }
    .subtext {
      font-size: 18px;
      letter-spacing: -1px;
    }
    hr {
      background-color: $green;
      width: 80px;
      height: 4px;
      align: left;
      margin-bottom: 24px;
    }
    .main {
      margin-top: 12px;
      h3 {
        color: black;
        font-weight: 700;
        font-size: 24px;
      }
      p {
      }
    }
  }
  .main-content-r {
    .border-card {
      border: 6px solid $green;
      align-items: center;
      text-align: center;
      padding: 16px;
      h2 {
        font-size: 24px;
        font-weight: 900;
      }
      .list {
        align-self: flex-start;
        ul {
          text-align: left;
          padding: 0;
          li {
            .check {
              padding: 0 8px;
              &::before {
                content: "\2713";
                height: 100%;
              }
            }
            color: $green;
            font-weight: 600;
            list-style: none;
            margin-bottom: 8px;
          }
        }
      }
    }
    .filled-card {
      color: white;
      margin-top: 16px;
      padding: 16px;
      border-radius: 4px;
      .title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
      }
      .custom-a {
        display: flex;

        .cta-arrow {
          max-width: 8px;
          display: inline-block;
          margin-left: 10px;
          transition: margin-left .3s;
        }
        &:hover {
          .cta-arrow {
            margin-left: 16px;
            transition: margin-left .3s;

          }
        }
      }

            a {
              color: #fff;
              font-size: 16px;
              display: block;
              overflow-wrap: break-word;
            }

    }
  }
}
