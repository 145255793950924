section.list-content {
  padding: 40px 0;
  .left {
    ul {
      list-style: none;
      li {
        max-width: 85%;
        &::before {
          content: "\2022";
          color: $green;
          font-weight: bold;
          display: inline-block;
          transform: scale(1.75);
          width: 20px;
          margin-left: -20px;
        }
      }
    }
    h2 {
      font-weight: 600;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    h4 {
      font-size: 24px;
      font-weight: 700;
    }
    .custom-a {
      display: flex;
      .cta-arrow {
        max-width: 8px;
        display: inline-block;
        margin-left: 10px;
        transition: margin-left .3s;

      }
      &:hover {
        .cta-arrow {
          margin-left: 16px;
          transition: margin-left .3s;

        }
      }
    }

  }
}
